// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-js": () => import("C:\\Users\\sherr\\Desktop\\ONQBA-Website\\src\\templates\\blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\sherr\\Desktop\\ONQBA-Website\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("C:\\Users\\sherr\\Desktop\\ONQBA-Website\\src\\pages\\about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("C:\\Users\\sherr\\Desktop\\ONQBA-Website\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("C:\\Users\\sherr\\Desktop\\ONQBA-Website\\src\\pages\\events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\sherr\\Desktop\\ONQBA-Website\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

